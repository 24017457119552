import React, { Component } from "react";

class Social extends Component {
  render() {
    return (
      <div className="profile-section pt-3 pt-lg-0">
        <div className="bio mb-3">Cloud Engine is a Leicester based IT consultancy specialising in Java and Web technology.</div>
        <ul className="social-list list-inline py-3 mx-auto">
          <li className="list-inline-item">
            <a target="_blank" rel="noopener noreferrer" href="mailto:mail@cloud-engine.co.uk">
              <i className="fas fa-envelope-open-text fa-fw"></i>
            </a>
          </li>
          <li className="list-inline-item">
            <a target="_blank" rel="noopener noreferrer" href="https://linkedin.com/in/matthew-russell-81a2776"
            >
              <i className="fab fa-linkedin-in fa-fw"></i>
            </a>
          </li>
          <li className="list-inline-item">
            <a target="_blank" rel="noopener noreferrer" href="https://gitlab.com/cloudengine">
              <i className="fab fa-gitlab fa-fw"></i>
            </a>
          </li>
          <li className="list-inline-item">
            <a target="_blank" rel="noopener noreferrer" href="https://stackoverflow.com/users/12878349/cloudengineuk">
              <i className="fab fa-stack-overflow fa-fw"></i>
            </a>
          </li>
        </ul>
        <hr></hr>
      </div>
    );
  }
}

export default Social;
