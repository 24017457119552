import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./component/Header.jsx";
import Main from "./component/Main.jsx";
import Resume from "./component/Resume.jsx";

class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">
          <Switch>
            <Route path="/resume_print" exact>
              <Resume source="mrussell_resume.json" />
            </Route>

            <Route path="/">
              <Header />
              <Main />
            </Route>
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
