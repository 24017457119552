import React, { Component } from "react";
import axios from "axios";

class RandomQuote extends Component {
  constructor(props) {
    super();

    this.quoteServiceUri = "https://quote.cloud-engine.co.uk/api/random";
    this.imageUriBase = "/assets/img/quotes/";

    this.state = {
      quoteId: null,
      quote: null,
      source: null,
      link: null,
      authorId: null,
      author: null,
      authorLink: null,
      themes: [],
    };
  }

  componentDidMount() {
    this.getQuote();
  }

  getQuote(theme) {
    let getQuoteUrl =
      this.quoteServiceUri + (theme !== undefined ? "/" + theme : "");

    axios
      .get(getQuoteUrl)
      .then((res) => {
        this.setState({
          quoteId: res.data.quoteId,
          quote: res.data.quote,
          source: res.data.source,
          sourceLink: res.data.link,
          authorId: res.data.author.authorId,
          author: res.data.author.author,
          authorLink: res.data.author.link,
          themes: res.data.themes,
        });
      })
      .catch((error) => {
        this.setState({
          quote:
            "Try not to become a man of success, but rather try to become a man of value.",
          authorId: 1,
          author: "Albert Einstein",
          authorLink: "https://en.wikipedia.org/wiki/Albert_Einstein",
          themes: [{ themeId: 18, tag: "aspiration" }],
        });

        console.log(error);
      });
  }

  handleRefreshButton = () => {
    this.getQuote();
  };

  handleRefreshByThemeButton = (ev) => {
    this.getQuote(ev.currentTarget.value);
  };

  render() {
    const {
      quote,
      source,
      sourceLink,
      authorId,
      author,
      authorLink,
      themes,
    } = this.state;

    let authoridString = authorId + "";
    let imageUri = this.imageUriBase + authoridString.padStart(5, "0") + ".jpg";

    return (
      <div>
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-9">
            <QuoteBox
              quote={quote}
              author={author}
              imageUri={imageUri}
              authorLink={authorLink}
              source={source}
              sourceLink={sourceLink}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-9">
            <ControllerBox
              themes={themes}
              refreshAction={this.handleRefreshButton}
              refreshByThemeAction={this.handleRefreshByThemeButton}
            />
          </div>
        </div>
      </div>
    );
  }
}

const QuoteBox = ({
  quote,
  author,
  imageUri,
  authorLink,
  source,
  sourceLink,
}) => {
  return (
    <React.Fragment>
      <div className="quote-holder">
        <div>
          <i className="fas fa-quote-left"></i>
          <blockquote>{quote}</blockquote>
        </div>

        <div className="source-holder">
          <div className="source-image">
            <img
              className="img-fluid rounded-circle"
              src={imageUri}
              alt={author}
            />
          </div>
          <div className="meta">
            <ul className="list-unstyled mb-0">
              <li className="mb-2">
                <a href={authorLink}>{author}</a>
              </li>
              <li className="mb-2">
                <a className="quote-source" href={sourceLink}>
                  {source}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const ControllerBox = ({ themes, refreshAction, refreshByThemeAction }) => {
  return (
    <React.Fragment>
      <div className="quote-control-holder">
        <button className="btn btn-primary" onClick={refreshAction}>
          <i className="fas fa-redo"></i>
        </button>

        {themes.map((themeItem) => (
          <button
            className="btn btn-outline-primary ml-2"
            key={themeItem.themeId}
            value={themeItem.tag}
            onClick={refreshByThemeAction}
          >
            {themeItem.tag}
          </button>
        ))}
      </div>
    </React.Fragment>
  );
};

export default RandomQuote;
