import React, { Component } from "react";
import { HashLink as Link } from "react-router-hash-link";
import SkillCards from "./SkillCards.jsx";
import Projects from "./Projects.jsx";
import RandomQuote from "./RandomQuote.jsx";


class Main extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="main-wrapper">
    
          <section id="welcome" className="p-3 p-lg-4 theme-bg-light">
            <div className="container">
              <div className="profile-teaser media row">
                <div className="media-body col-sm-12 col-md-6 col-lg-5 col-xl-5">
                  <h2 className="section-title font-weight-bold mb-4">Welcome</h2>
                  <div className="bio mb-4">
                    <p>Want to know how Cloud Engine can help your project? Check out the <a className="link-on-bg" href="/resume">online resume</a> of Senior Software Engineer Matt detailing over 25
                      years experience delivering software solutions to defence, science and financial sectors.</p>
                  </div>
                  <div className="mb-4">
                    <Link to="/resume_print" target="_blank" rel="noopener noreferrer">
                    <button className="btn btn-primary mb-3"><i className="fas fa-file-alt mr-2"></i><span className="d-none d-md-inline">View</span> Resume</button>
                    </Link>
                  </div>
                </div>
                <div className="col-sm-12 col-md-4 col-lg-5 col-xl-4 my-auto text-center">
                  <img className="profile-image" src="/assets/img/mnrussell_profile_350x299.png" alt="Matt Russell" />
                </div>
              </div>
            </div>
          </section>

          <section id="toolset" className="toolset-overview-section p-3 p-lg-4">
            <div className="container">
              <h2 className="section-title font-weight-bold mb-3">Our Toolset</h2>
              <SkillCards source="tech.json" cols="3" />
            </div>
          </section>

          <section id="projects" className="projects-list p-3 p-lg-4">
            <div className="container">
              <h2 className="section-title font-weight-bold mb-4">Projects</h2>
              <Projects source="projects.json" />
            </div>
          </section>

          <section id="quotes" className="quotes-section p-3 p-lg-3">
            <div className="container">
              <h2 className="section-title font-weight-bold mb-3">Quotes</h2>
              <RandomQuote />
            </div>
          </section>

          <footer className="footer text-center py-4">
            <small className="copyright">
              Website Design &copy; Cloud-Engine Limited.
            </small>
          </footer>
        </div>

        { /*
        <div id="config-panel" className="config-panel d-none d-lg-block">
          <div className="panel-inner">
            <a id="config-trigger" className="config-trigger config-panel-hide text-center" href="#"><i className="fas fa-cog mx-auto" data-fa-transform="down-6"></i></a>
            <h5 className="panel-title">Choose Colour</h5>
            <ul id="color-options" className="list-inline mb-0">
              <li className="theme-7 active list-inline-item"><a data-style="/assets/css/theme-7.css" href="#"></a></li>
              <li className="theme-1  list-inline-item"><a data-style="/assets/css/theme-1.css" href="#"></a></li>
              <li className="theme-2  list-inline-item"><a data-style="/assets/css/theme-2.css" href="#"></a></li>
              <li className="theme-3  list-inline-item"><a data-style="/assets/css/theme-3.css" href="#"></a></li>
              <li className="theme-4  list-inline-item"><a data-style="/assets/css/theme-4.css" href="#"></a></li>
              <li className="theme-5  list-inline-item"><a data-style="/assets/css/theme-5.css" href="#"></a></li>
              <li className="theme-6  list-inline-item"><a data-style="/assets/css/theme-6.css" href="#"></a></li>
              <li className="theme-8  list-inline-item"><a data-style="/assets/css/theme-8.css" href="#"></a></li>
              <li className="theme-9  list-inline-item"><a data-style="/assets/css/theme-9.css" href="#"></a></li>
              <li className="theme-10  list-inline-item"><a data-style="/assets/css/theme-10.css" href="#"></a></li>
            </ul>
            <a id="config-close" className="close" href="#"><i className="fa fa-times-circle"></i></a>
          </div>
        </div>
        */ }
      </React.Fragment>
    );
  }
}

export default Main;
