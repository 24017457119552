import React, { Component } from "react";
import axios from "axios";

class SkillCards extends Component {
  constructor(props) {
    super();

    this.state = {
      skillSets: [],
      cols: ""
    };
  }

  componentDidMount() {
    axios
      .get(this.props.source)
      .then(result => {
        this.setState({
          skillSets: result.data
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    const { skillSets } = this.state;

    let rows = create2DArray(skillSets, this.props.cols);

    return (
      <React.Fragment>
        {Object.keys(rows).map((row, index) => {
          return (
            <div className="row" key={index}>
              {rows[row].map((item, index) => {
                return (
                  <div key={index} className="item col-sm-4 col-md-4 col-lg-3">
                    <div className="tech-item">
                      <div className="item-icon">
                        <IconList iconArray={item.techIcons} />
                      </div>
                      <h3 className="item-title">{item.title}</h3>
                      <div className="item-desc">{item.desc}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </React.Fragment>
    );
  }
}

const IconList = ({ iconArray }) => {
  return (
    <React.Fragment>
      {iconArray.map((iconItem, index) => {
        if (iconItem.icon.startsWith("/")) {
          return (
            <img
              key={iconItem.icon}
              src={iconItem.icon}
              alt={iconItem.tech}
              width="35"
              height="35"
              className="align-middle"
            />
          );
        }

        var iconClassName =
          iconItem.icon + " align-middle " + (index > 0 ? " ml-2" : "");

        return <i key={index} className={iconClassName} alt={iconItem.tech} />;
      })}
    </React.Fragment>
  );
};

function create2DArray(items, columns) {
  let rows = {};
  let counter = 1;

  items.forEach((item, idx) => {
    rows[counter] = rows[counter] ? [...rows[counter]] : [];
    if (idx % columns === 0 && idx !== 0) {
      counter++;
      rows[counter] = rows[counter] ? [...rows[counter]] : [];
      rows[counter].push(item);
    } else {
      rows[counter].push(item);
    }
  });
  return rows;
}

export default SkillCards;
