import React, { Component } from "react";
import { HashLink as Link } from "react-router-hash-link";
import Social from "./Social.jsx";

class Header extends Component {
  render() {
    return (
      <header className="header text-center">
        <div className="force-overflow">
          <div className="container">
            <h1 className="company-name pt-lg-4 mb-0">
              <a href="/">
                CloudEngine <i className="center-block fas fa-cogs fa-sm"></i>
              </a>
            </h1>
          </div>

          <nav className="navbar navbar-expand-lg navbar-dark">
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navigation"
              aria-controls="navigation"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              id="navigation"
              className="collapse navbar-collapse flex-column"
            >
              <div id="social">
                <Social />
              </div>

              <ul className="navbar-nav flex-column text-left">
                <NavLinkListItem
                  uri="/#welcome"
                  iconClassname="fas fa-home fa-fw mr-2"
                  text="Welcome"
                />
                <NavLinkListItem
                  uri="/#toolset"
                  iconClassname="fas fa-toolbox fa-fw mr-2"
                  text="Toolset"
                />
              </ul>
            </div>
          </nav>
        </div>
      </header>
    );
  }
}

function NavLinkListItem(props) {
  return (
    <Link to={props.uri} className="nav-link">
      <li className="nav-item">
        <i className={props.iconClassname}></i>
        {props.text}
      </li>
    </Link>
  );
}

export default Header;
