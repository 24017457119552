import React, { Component } from "react";
import axios from "axios";

class Projects extends Component {
  constructor(props) {
    super();

    this.state = {
      projects: [],
    };
  }

  componentDidMount() {
    axios
      .get(this.props.source)
      .then((result) => {
        this.setState({
          projects: result.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    const { projects } = this.state;
    
    return (
      <React.Fragment>
        <div className="project-cards row">
          {projects.map((eachProject, index) => (
            <div key={index} className={"col-md-6 col-lg-5 mb-3"}>
              <div className="card project-card">
                <div className="row no-gutters">
                  <div className="col-lg-4 card-img-holder">
                    <img
                      className="card-img"
                      src={"/assets/img/project/" + eachProject.image}
                      alt={eachProject.image}
                    />
                  </div>
                  <div className="col-lg-8">
                    <div className="card-body">
                      <h5 className="card-title">
                        <a href={eachProject.link} className="theme-link">
                          {eachProject.title}
                        </a>
                      </h5>
                      <p className="card-text">{eachProject.desc}</p>
                      <ul className="list-unstyled">
                        {eachProject.bullets.map((eachBullet, index) => (
                          <li key={index}>
                            <p className="card-text">{eachBullet}</p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}

export default Projects;
